<template>

  <div>
    <checker />
    <b-row class="d-print-none mb-1">
      <b-col cols="12">
        <b-button @click="print" cols="12">
        Download PDF
        </b-button>
      </b-col>
    </b-row>
    <div ref="content" id="content" v-if="Object.keys(report).length > 0">
      <div v-for="(category, index) in Object.values(categories)" :key="category.title">
        <b-card>
          <b-link v-b-toggle="`${index}`"><h2 class="d-inline-block mr-1" variant="primary">{{ index + 1 + '. ' + category }}</h2>
            {{ report[category].failed }} failed
            {{ report[category].passed }} passed
          </b-link>
          <b-collapse :id="`${index}`">
            <p>{{ report[category].description }}</p>
            <div v-for="(subCategory, sindex) in Object.keys(report[category]['subCategories'])" :key="subCategory">
              <h3>{{ index + 1 }}.{{ sindex + 1 }}. {{subCategory}}</h3>
              <p>{{ report[category]['subCategories'][subCategory].description }}</p>
                <div v-for="(scenario, tindex) in Object.values(report[category]['subCategories'][subCategory].tests).sort( bySeverity )" :key="scenario.id">
                <b-row class="mb-2 border-bottom">
                  <b-col cols="12">
                    <h4>
                      {{ (index + 1) + '.' + (sindex + 1) + '.' + (tindex + 1) + '. ' + scenario.action }}
                    </h4>
                    <div>
                      {{ scenario.overview }}
                    </div>
                    <div>
                      <b>Potential Impact:</b> {{ scenario.impact.join(", ") }}
                    </div>
                    <div>
                      <b>Root Cause Issue:</b> {{ scenario.root.join(", ") }}
                    </div>
                    <div>
                      <b>Severity:</b>
                      <b-badge
                        :variant="`light-${getColor(scenario.priority)}`"
                        class="ml-1"
                      >
                        {{ scenario.priority }}
                      </b-badge>
                    </div>
                    <div v-if="scenario.standards">
                      <h5 class="mt-1">
                        Impacted Standards
                      </h5>
                      <div v-for="standard in Object.keys(scenario.standards)" :key="standard">
                        <b>{{ capitalizeWords(standard) }}:</b> {{ scenario.standards[standard] }}
                      </div>
                    </div>
                    <h5 class="mt-1">
                      Active Applications
                    </h5>
                    <div>
                      <div v-if="scenario.applications">
                        <div class="my-1" v-if="Object.keys(scenario.applications).length > 0">
                          <div v-for="application in Object.keys(scenario.applications)"
                            :key="application">
                            <div class="my-1">
                              <img
                                width="18"
                                style="margin-right: 4px"
                                :src="require(`@/assets/images/logos/${application}.webp`)"
                              >{{ application }}
                              <b-badge
                                :variant="`light-${scenario.applications[application].failed ? 'danger' : 'success'}`"
                                class="ml-1"
                              >
                                {{ scenario.applications[application].failed ? 'Failed: ' + scenario.error : 'Passed' }}
                              </b-badge>
                            </div>
                            <div v-if="scenario.applications[application].resources &&
                              scenario.applications[application].resources.length">
                              <h6>Risky Resources</h6>
                              <b-table
                                class="position-relative border-bottom boder-0"
                                :items="scenario.applications[application].resources"
                                :fields="scenario.applications[application].resourcesFields"
                                responsive
                                show-empty>
                                <template #cell(name)="data">
                                  <img :src="data.item.type">{{" " + data.item.name}}
                                </template>
                                <template #cell(permissions)="data">
                                  <div v-for="permission in data.item.permissions" :key="permission">
                                    {{ permission }}
                                  </div>
                                </template>
                                <template #cell(path)="data">
                                  <span v-if="data.item.path && data.item.path.startsWith('http')">
                                    <a target="_blank" :href="data.item.path">link</a>
                                  </span>
                                  <span v-else>
                                    {{data.item.path}}
                                  </span>
                                </template>
                                <template #cell(raw)="data">
                                  <a v-b-modal="`modal-${data.item.name}`">view</a>
                                  <b-modal
                                    hide-footer
                                    hide-header
                                    size="lg"
                                    :id="`modal-${data.item.name}`"
                                    >
                                    <json-viewer :value="data.item.raw"></json-viewer>
                                  </b-modal>
                                </template>
                                <template #cell(users)="data">
                                  <a v-b-modal="`modal-users-${data.item.name}`">{{ data.item.users.length }} users</a>
                                  <b-modal
                                    hide-footer
                                    hide-header
                                    size="lg"
                                    :id="`modal-users-${data.item.name}`"
                                    >
                                    <div v-for="user in data.item.users" :key="user">
                                    {{ user }}
                                    </div>
                                  </b-modal>
                                </template>
                              </b-table>
                            </div>
                            <div v-if="scenario.applications[application].actors &&
                                      scenario.applications[application].actors.length">
                              <h6>Risky Identities</h6>
                              <b-table
                                class="position-relative p-0 m-0 border-right"
                                :items="scenario.applications[application].actors"
                                :fields="scenario.applications[application].actorsFields"
                                responsive
                                show-empty>
                                <template #cell(name)="data">
                                  <feather-icon
                                    icon="UserIcon"
                                  /> {{data.item.name}}
                                </template>
                                <template #cell(groups)="data">
                                  {{data.item.groups.join(", ")}}
                                </template>
                                <template #cell(raw)="data">
                                  <a v-b-modal="`modal-${data.item.name}`">view</a>
                                  <b-modal
                                    hide-footer
                                    hide-header
                                    size="lg"
                                    :id="`modal-${data.item.name}`"
                                    >
                                    <json-viewer :value="data.item.raw"></json-viewer>
                                  </b-modal>
                                </template>
                              </b-table>
                            </div>
                              <div v-if="scenario.applications[application].configurations &&
                                      scenario.applications[application].configurations.length">
                              <h6>Risky Configurations</h6>
                              <b-table
                                class="position-relative p-0 m-0 border-right"
                                :items="scenario.applications[application].configurations"
                                :fields="scenario.applications[application].configurationsFields"
                                responsive
                                show-empty>
                                  <template #cell(raw)="data">
                                    <a v-b-modal="`modal-${data.item.name}`">view</a>
                                    <b-modal
                                      hide-footer
                                      hide-header
                                      size="lg"
                                      :id="`modal-${data.item.name}`"
                                      >
                                      <json-viewer :value="data.item.raw"></json-viewer>
                                    </b-modal>
                                  </template>
                              </b-table>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          There are no active applications relevant to this scenario
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BBadge, BCollapse, BLink,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import useScenariosList from './api/useScenariosList'
import scenarioStoreModule from './api/scenarioStoreModule'
import checker from './Checker.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
    BLink,
    checker,
    BCollapse,
  },
  data() {
    return {
      option: [],
      selected: [],
      toggledServices: [],
      states: {},
      scenarios: [],
      perPage: 25,
      report: {},
      currentPage: 1,
      categories: ['Super Admins', 'Identity Access Management', 'Resources', 'Chat & Conferences', 'Incident Response'],
    }
  },
  mounted() {
    const report = JSON.parse(localStorage.getItem('report'))
    if (report) {
      this.report = report
    }
    this.fetchReport(null, this.initReport)
  },
  methods: {
    initReport(report) {
      this.report = report
      this.report = JSON.parse(localStorage.getItem('report'))
    },
    print() {
      window.print()
    },
    bySeverity(a, b) {
      if (a.priority === 'High') {
        return -1
      }
      if (a.priority === 'Medium' && b.priority !== 'High') {
        return -1
      }
      return 1
    },
  },
  setup() {
    const SCENARIO_APP_STORE_MODULE_NAME = 'app-scenario'

    // Register module
    if (!store.hasModule(SCENARIO_APP_STORE_MODULE_NAME)) store.registerModule(SCENARIO_APP_STORE_MODULE_NAME, scenarioStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SCENARIO_APP_STORE_MODULE_NAME)) store.unregisterModule(SCENARIO_APP_STORE_MODULE_NAME)
    })

    const {
      fetchReport,
    } = useScenariosList()

    return {
      fetchReport,
    }
  },
  props: {
    query: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss">
.customizer {
  visibility: hidden;
}
.h2:focus {
  outline: none;
  box-shadow: none;
}
</style>
