<template>
  <b-row v-if="showMsg">
    <b-col cols="12" class="text-center">
      <b-card
        bg-variant="primary"
        text-variant="white"
      >
        <b>There are no active cloud apps</b>
        <b-link class="ml-2 btn btn-warning" to="/integrations">Onboard Now</b-link>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import store from '@/store'
import {
  BRow,
  BCol,
  BCard,
  BLink,
} from 'bootstrap-vue'
import useApplicationsList from './api/useApplicationsList'
import applicationStoreModule from './api/applicationStoreModule'

export default {
  components: {
    BRow,
    BLink,
    BCol,
    BCard,
  },
  data() {
    return {
      showMsg: false,
    }
  },
  mounted() {
    this.fetchChecker(null, this.initChecker)
  },
  methods: {
    initChecker(empty) {
      if (empty) {
        this.$router.push('/integrations')
      }
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-application'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, applicationStoreModule)

    const {
      fetchChecker,
    } = useApplicationsList()

    return {
      fetchChecker,
    }
  },
}
</script>
